<template>
	<div>
		<app-layout>
			<template v-slot:header>
				<Header :title="$t('vote_count_with_members')"
                        :is-filter="false"
				        @filter-div-status="datatable.filterStatus = $event">
				</Header>
			</template>
			<template v-slot:header-mobile>
				<HeaderMobile :title="$t('vote_count_with_members')"
                              :is-filter="false"
				              @filter-div-status="datatable.filterStatus = $event">
				</HeaderMobile>
			</template>
			<datatable :isLoading.sync="datatable.isLoading"
			           :columns="datatable.columns"
			           :rows="datatable.rows"
			           :total="datatable.total"
			           :queryParams="datatable.queryParams"
			           :lineNumbers="false"
			           @on-page-change="onPageChange"
			           @on-sort-change="onSortChange"
			           @on-per-page-change="onPerPageChange"
			           v-show="datatable.showTable">
			</datatable>
		</app-layout>
	</div>
</template>
<script>
    //Template
	import AppLayout from "@/layouts/AppLayout"
	import Header from "@/layouts/AppLayout/Header"
	import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"

    //Component
    import Datatable from "@/components/datatable/Datatable";

    // Services
    import StudentClubSelectionSearchService from '@/services/StudentClubSelectionSearchService';

    // Other
	import qs from 'qs'
	import {ValidationObserver, ValidationProvider} from "vee-validate";

	export default {
		components: {
			AppLayout,
			Header,
			HeaderMobile,

			Datatable,
			ValidationProvider,
			ValidationObserver,
		},
		metaInfo() {
			return {
				title: this.$t('vote_count_with_members')
			}
		},
		data() {
			return {
				id: 0,
				datatable: {
					isLoading: false,
					columns: [
						{
							label: this.$t('id'),
							field: 'id',
							sortable: true,
                            hidden:false
						},
						
                        {
							label: this.$t('full_name'),
							field: 'name',
							sortable: true,
                            hidden:false
                        },
                        {
							label: this.$t('telephone'),
							field: 'phone',
							sortable: true,
                            hidden:false
                        },
					],
					rows: [],
					total: 0,
					filterStatus: true,
					showTable: false,
					queryParams: {
						filter: {},
						sort: 'id',
						page: 1,
						limit: 20
					}
				}
			}
		},
		methods: {
			filter() {
                this.datatable.queryParams.page = 1;
				this.getRows();
			},
			onPageChange(params) {
				this.datatable.queryParams.page = params.currentPage;
				this.getRows();
			},
			onPerPageChange(params) {
				this.datatable.queryParams.limit = params.currentPerPage;
				this.datatable.queryParams.page = 1;
				this.getRows();
			},
			onSortChange(params) {
				const sortType = params[0].type == 'desc' ? '-' : '';
				this.datatable.queryParams.sort = sortType + params[0].field
				this.getRows();
			},
			getRows() {
				this.datatable.showTable = true;
				this.datatable.isLoading = true;

				const config = {
					params: {
						...this.datatable.queryParams
					},
					paramsSerializer: (params) => qs.stringify(params, {encode: false})
				};

				return StudentClubSelectionSearchService.getAllLists(config)
				                     .then((response) => {
					                     this.datatable.rows = response.data.data

				                     })
				                     .finally(() => {
					                     this.datatable.isLoading = false;
				                     });
			},
		},
		created() {
			this.filter();
		}
	};
</script>

